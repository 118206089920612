export default [
  'Acura',
  'Alfa Romeo',
  'Alpina',
  'Asia',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Brilliance',
  'Bugatti',
  'Buick',
  'BYD',
  'Cadillac',
  'Changan',
  'Chery',
  'Chevrolet',
  'Chrysler',
  'Citroen',
  'Dacia',
  'Daewoo',
  'Daihatsu',
  'Daimler',
  'Datsun',
  'DeLorean',
  'Derways',
  'Dodge',
  'Dongfeng',
  'DW Hower',
  'Eagle',
  'FAW',
  'Ferrari',
  'Fiat',
  'Fisker',
  'Ford',
  'Foton',
  'GAC',
  'Geely',
  'Genesis',
  'Geo',
  'GMC',
  'Great Wall',
  'Hafei',
  'Haima',
  'Haval',
  'Hawtai',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Iran Khodro',
  'Isuzu',
  'JAC',
  'Jaguar',
  'Jeep',
  'Kia',
  'Koenigsegg',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lifan',
  'Lincoln',
  'Lotus',
  'Luxgen',
  'Marussia',
  'Maserati',
  'Maybach',
  'Mazda',
  'McLaren',
  'Mercedes-Benz',
  'Mercury',
  'Mini',
  'Mitsubishi',
  'Mitsuoka',
  'Nissan',
  'Oldsmobile',
  'Opel',
  'Pagani',
  'Peugeot',
  'Plymouth',
  'Pontiac',
  'Porsche',
  'Proton',
  'Ravon',
  'Renault',
  'Rover',
  'Saab',
  'Saturn',
  'SEAT',
  'Skoda',
  'Smart',
  'SsangYong',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
  'Zotye',
  'ВАЗ',
  'ГАЗ',
  'ЗАЗ',
  'ИЖ',
  'Лада',
  'ЛуАЗ',
  'Москвич',
  'ТагАЗ',
  'УАЗ',
  'Прочие авто'
]
