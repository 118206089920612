import { Link, navigate } from 'gatsby'

import React from 'react'
import styles from './TopMenu.module.css'

export default function TopMenu() {
  return (
    <ul className={styles.topMenu}>
      <MenuItem to="/" title="Главная" />
      {/*
      <MenuItem to="/about" title="О нас" />
*/}
      <MenuItem title="Услуги">
        <ul>
          <MenuItem to="/#buy" title="Покупка авто в Москве" />
          <MenuItem to="/#buy" title="Покупка авто в Подмосковье" />
          <MenuItem to="/#buy" title="Покупка авто у юр. лиц" />
          <MenuItem to="/#buy" title="Покупка авто у физ. лиц" />
          {/*
          <MenuItem to="#" title="Выкуп авто в Замоскворечье" />
          <MenuItem to="#" title="Выкуп авто в Тушино" />
          */}
        </ul>
      </MenuItem>
      <MenuItem to="/bought-cars/" title="Купленные авто" />
      <MenuItem to="/#contacts" title="Контакты" />
    </ul>
  )
}

function MenuItem({ to, title, children }) {
  return (
    <React.Fragment>
      {to !== undefined ? (
        <li onClick={() => navigate(to)} aria-hidden="true">
          <Link to={to}>{title}</Link>
          {children}
        </li>
      ) : (
        <li className={styles.nolink} aria-hidden="true">
          <span>{title}</span>
          {children}
        </li>
      )}
    </React.Fragment>
  )
}
