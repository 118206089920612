import { graphql, useStaticQuery } from 'gatsby'

import BuyoutForm from './BuyoutForm'
// import { Container } from 'react-bootstrap'
import React from 'react'
import TopMenu from './TopMenu'
import Trackers from './Trackers'
import styles from './MainLayout.module.css'

export default function Layout({ children, siteHeader = true, pageTitle }) {
  const data = useStaticQuery(graphql`
    query SiteImagesQuery {
      allFile(filter: { sourceInstanceName: { eq: "site-img" } }) {
        nodes {
          base
          img: childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.nodes.reduce((map, obj) => {
    map[obj.base] = obj.img.fluid.src
    return map
  }, {})

  return (
    <React.Fragment>
      <header>
        <div className={styles.topPanel}>
          <span>8 (930) 976-44-92</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>buymycar24@mail.ru</span>
        </div>
        <div className={styles.topMenuContainer}>
          <TopMenu />
        </div>
        {siteHeader && (
          <React.Fragment>
            <div className={styles.tilt1}></div>
            <div className={styles.titleContainer} style={{ backgroundImage: `url(${images['bmw-x4-m40i-2016.png']})` }} >
              <div className={styles.titleImageLeft} style={{ backgroundImage: `url(${images['russian-ruble.png']})` }}>
                  <div className={styles.buyoutForm}>
                    <h2>Заявка на оценку автомобиля</h2>
                    <BuyoutForm />
                  </div>
              </div>
            </div>
            <div className={styles.tilt2}></div>
            <div className={styles.info}>
              <Info />
            </div>
          </React.Fragment>
        )}
        {pageTitle && (
          <React.Fragment>
            <div className={styles.tilt3}></div>
            <div className={styles.pageHeader}>
              <div className="container">
                <h1>{pageTitle}</h1>
              </div>
            </div>
          </React.Fragment>
        )}
      </header>
      <main>{children}</main>
      <footer></footer>
      <Trackers />
    </React.Fragment>
  )
}

function Info() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <h4>Быстро</h4>
          <p>Наш специалист прибудет для осмотра и последующего совершения сделки так скоро, насколько это возможно</p>
        </div>
        <div className="col-sm">
          <h4>Выгодно</h4>
          <p>Предложим цену выше, чем у конкурентов</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <h4>Без рисков</h4>
          <p>Честно, надежно, никакого мошенничества</p>
        </div>
        <div className="col-sm"></div>
      </div>
    </div>
  )
}
