import NumberFormat from 'react-number-format'
import React from 'react'
import carBrands from '../data/car-brands'
import classNames from 'classnames'
import transmissionsTypes from '../data/transmission-types'

export default function BuyoutForm() {
  const [fieldValues, setFieldValues] = React.useState({
    brand: '',
    model: '',
    year: '',
    run: '',
    engine: '',
    transmission: '',
    desiredPrice: '',
    contactName: '',
    contactPhone: ''
  })

  const [requestSent, setRequestSent] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [validationErrors, setValidationErrors] = React.useState({})
  const [globalError, setGlobalError] = React.useState(false)

  const updateFieldValue = (fieldName, newValue) => {
    setFieldValues({
      ...fieldValues,
      [fieldName]: newValue
    })
  }

  const validateRequired = () => {
    const errors = {}

    if (fieldValues.brand === '') {
      errors.brand = 'Выберите марку'
    }

    if (fieldValues.model === '') {
      errors.model = 'Укажите модель'
    }

    if (fieldValues.year === '') {
      errors.year = 'Укажите год'
    }

    if (fieldValues.run === '') {
      errors.run = 'Укажите пробег'
    }

    if (fieldValues.transmission === '') {
      errors.transmission = 'Выберите коробку передач'
    }

    if (fieldValues.contactName === '') {
      errors.contactName = 'Укажите имя'
    }

    if (fieldValues.contactPhone === '' || fieldValues.contactPhone.includes('_')) {
      errors.contactPhone = 'Укажите контактный телефон'
    }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!validateRequired()) {
      return false
    }

    const reqData = {
      brand: fieldValues.brand,
      model: fieldValues.model,
      year: parseInt(fieldValues.year),
      run: parseInt(fieldValues.run),
      engine: fieldValues.engine,
      transmission: fieldValues.transmission,
      desiredPrice: parseInt(fieldValues.desiredPrice),
      contactName: fieldValues.contactName,
      contactPhone: fieldValues.contactPhone
    }

    setSending(true)

    const resp = await fetch(`/api/buyout`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(reqData)
    })

    setSending(false)

    if (resp.ok) {
      setRequestSent(true)
      setGlobalError(false)
      setValidationErrors({})
    } else if (resp.status === 400) {
      setGlobalError(false)

      const data = await resp.json()
      setValidationErrors(data.validationErrors === undefined ? {} : data.validationErrors)
    } else {
      console.log('Request error', resp)
      setGlobalError(true)
      setValidationErrors({})
    }
  }

  if (requestSent) {
    return (
      <div className="alert alert-success" role="alert">
        Заявка отправлена. Мы свяжемся с Вами в ближайшее время.
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {globalError && (
        <div className="alert alert-danger" role="alert">
          Возникла ошибка при отправке заявки, попробуйте повторить через некоторое время.
        </div>
      )}
      <div className="form-row form-group">
        <div className="col">
          <select
            className={classNames('custom-select', {
              'is-invalid': validationErrors.brand !== undefined
            })}
            value={fieldValues.brand}
            onChange={evt => updateFieldValue('brand', evt.target.value)}>
            <option value="">Марка *</option>
            {carBrands.map(b => (
              <option key={b} value={b}>
                {b}
              </option>
            ))}
          </select>
          <ValidationError errors={validationErrors} field="brand" />
        </div>
        <div className="col">
          <input
            className={classNames('form-control', {
              'is-invalid': validationErrors.model !== undefined
            })}
            type="text"
            placeholder="Модель *"
            maxLength={24}
            value={fieldValues.model}
            onChange={evt => updateFieldValue('model', evt.target.value)}
          />
          <ValidationError errors={validationErrors} field="model" />
        </div>
      </div>

      <div className="form-row form-group">
        <div className="col">
          <NumberFormat
            className={classNames('form-control', {
              'is-invalid': validationErrors.year !== undefined
            })}
            type="text"
            placeholder="Год *"
            format="####"
            value={fieldValues.year}
            onValueChange={values => updateFieldValue('year', values.value)}
          />
          <ValidationError errors={validationErrors} field="year" />
        </div>
        <div className="col">
          <NumberFormat
            className={classNames('form-control', {
              'is-invalid': validationErrors.run !== undefined
            })}
            type="text"
            placeholder="Пробег, км *"
            decimalScale={0}
            thousandSeparator=" "
            maxLength={8}
            value={fieldValues.run}
            onValueChange={values => updateFieldValue('run', values.value)}
          />
          <ValidationError errors={validationErrors} field="run" />
        </div>
      </div>

      <div className="form-row form-group">
        <div className="col">
          <input
            className={classNames('form-control', {
              'is-invalid': validationErrors.engine !== undefined
            })}
            type="text"
            placeholder="Двигатель"
            maxLength={32}
            value={fieldValues.engine}
            onChange={evt => updateFieldValue('engine', evt.target.value)}
          />
          <ValidationError errors={validationErrors} field="engine" />
        </div>
        <div className="col">
          <select
            className={classNames('custom-select', {
              'is-invalid': validationErrors.transmission !== undefined
            })}
            value={fieldValues.transmission}
            onChange={evt => updateFieldValue('transmission', evt.target.value)}>
            <option value="">Коробка передач *</option>
            {transmissionsTypes.map(t => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
          </select>
          <ValidationError errors={validationErrors} field="transmission" />
        </div>
      </div>

      <div className="form-row form-group">
        <div className="col">
          <NumberFormat
            className="form-control"
            type="text"
            placeholder="Желаемая цена, руб"
            decimalScale={0}
            thousandSeparator=" "
            maxLength={10}
            value={fieldValues.desiredPrice}
            onValueChange={values => updateFieldValue('desiredPrice', values.value)}
          />
        </div>
        <div className="col">&nbsp;</div>
      </div>

      <div className="form-row form-group">
        <div className="col">
          <input
            className={classNames('form-control', {
              'is-invalid': validationErrors.contactName !== undefined
            })}
            type="text"
            placeholder="Ваше имя *"
            maxLength={32}
            value={fieldValues.contactName}
            onChange={evt => updateFieldValue('contactName', evt.target.value)}
          />
          <ValidationError errors={validationErrors} field="contactName" />
        </div>
        <div className="col">
          <NumberFormat
            className={classNames('form-control', {
              'is-invalid': validationErrors.contactPhone !== undefined
            })}
            placeholder="Телефон *"
            type="text"
            format="+7(###)###-##-##"
            mask="_"
            value={fieldValues.contactPhone}
            onValueChange={values => updateFieldValue('contactPhone', values.formattedValue)}
          />
          <ValidationError errors={validationErrors} field="contactPhone" />
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <button className="btn btn-primary" disabled={sending}>
          Отправить заявку
        </button>
      </div>
    </form>
  )
}

function ValidationError({ errors, field }) {
  return <React.Fragment>{errors[field] !== undefined && <div className="invalid-feedback">{errors[field]}</div>}</React.Fragment>
}
